<template>
    <div>
        <v-textarea
            name="input-7-1"
            :label="label"
            v-model="model"
            :hint="hint"
            :rules="computeFieldRules()"
            :no-resize="noResize"
            :rows="rows"
            @change="emitValue"
            :counter="counterValue"
            :maxlength="counterValue"
        ></v-textarea>
        <template v-if="semaforo">
            <p style="color: orange;">{{ mensajeSemaforo }}</p>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        hint: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        valor: {
            type: String
        },
        noResize: {
            type: Boolean,
            default: false
        },
        rows: {
            type: Number,
            default: 4
        },
        semaforo: {
            type: Boolean,
            default: false
        },
        counterValue: {
            type: Number
        }
    },
    data() {
        return {
            rules: {
                required: (val) => (val || "").length > 0 || "Este campo es obligatorio.",
                semaforo: value => {
                    if (value != null && value != "" && value.length > 0) {
                        this.mensajeSemaforo = ''
                    } else {
                        this.mensajeSemaforo = 'Campo necesario para completar compromiso'
                    }
                    return true
                },
                largo: (val) => {
                    if (this.counterValue && val) {
                        return val.length <= this.counterValue || "El texto no debe exceder los " + this.counterValue + " caracteres"
                    }
                    return true
                }
            },
            modelo: '',
            mensajeSemaforo: '',
            editado: false
        }
    },
    methods: {
        getValue() {
            return this.modelo;
        },
        setValue(value) {
            this.modelo = value;
        },
        computeFieldRules() {
            if (this.semaforo) {
                return [this.rules.semaforo, this.rules.largo]
            }
            return this.required ? [this.rules.required, this.rules.largo] : [this.rules.largo];
        },
        getEditado() {
            return this.editado
        },
        setEditado(editado) {
            this.editado = editado
        },
        emitValue() {
            this.$emit("value", this.modelo);
        }
    },
    mounted() {
        this.model = this.valor
    },
    computed: {
        model: {
            get: function() {
                return this.modelo
            },
            set: function(newValue) {
                if ((this.model && this.model != '') && (newValue && newValue != '')) {
                    this.editado = true
                }
                this.modelo = newValue;
            }
        }
    }
}
</script>
