<template>
     <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="dateFormatted"
                        :label="label"
                        persistent-hint
                        prepend-icon="mdi-calendar"
                        v-bind="attrs"
                        v-on="on"
                        dense
                        @input="handleInput"
                        :rules="required ? [rules.required] : []"
                        @keydown="validateInput"
                        :maxlength="10"
                        :readonly="readonly"
                    ></v-text-field>
                </template>
                <v-date-picker
                    v-model="date"
                    no-title
                    @input="menu1 = false"
                    locale="es"
                    first-day-of-week="1"
                    dateFormat="dd-MM-yyyy"
                ></v-date-picker>
            </v-menu>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            label: {
                type: String,
                required: true
            },
            valor: {
                type: String
            },
            required: {
                type: Boolean,
                default: false
            },
            readonly: {
                type: Boolean,
                default: true
            }
        },
        data: vm => ({
            date: null,
            //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            menu1: false,
            rules: {
                required: (val) => (val || "").length > 0 || "Este campo es obligatorio."
            },
            modelo: '',
            editado: false
        }),
        computed: {
            computedDateFormatted () {
                return this.formatDate(this.date)
            },
            dateFormatted: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    if ((this.date && this.date != '') && (newValue && newValue != '')) {
                        this.editado = true
                    }
                    this.modelo = newValue;
                }
            },
            numericValue() {
                if(this.dateFormatted) {
                    return this.dateFormatted.replace(/\D/g, ''); // Filtrar solo números
                }
            }
        },
        watch: {
            date (val) {
                this.dateFormatted = this.formatDate(this.date)
            },
        },
        methods: {
            formatDate (date) {
                if (!date) return null
                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            getValue() {
                if (!this.dateFormatted) return null
                const [day, month, year] = this.dateFormatted.includes('-') ? this.dateFormatted.split('-') : this.dateFormatted.split('/')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
            setValue(value) {
                this.dateFormatted = value;
            },
            handleInput() {
                const numericValue = this.numericValue;
                if (numericValue) {
                    if (numericValue.length >= 2 && numericValue.length < 4) {
                        this.dateFormatted = `${numericValue.substr(0, 2)}-`;
                    } else if (numericValue.length >= 4 && numericValue.length < 6) {
                        this.dateFormatted = `${numericValue.substr(0, 2)}-${numericValue.substr(2, 2)}-`;
                    } else if (numericValue.length >= 6) {
                        this.dateFormatted = `${numericValue.substr(0, 2)}-${numericValue.substr(2, 2)}-${numericValue.substr(4, 4)}`;
                    } else {
                        this.dateFormatted = numericValue;
                    }
                }
            },
            validateInput(event) {
                const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "/"];
                if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
                    event.preventDefault();
                }
            },
            getEditado() {
                return this.editado
            },
            setEditado(editado) {
                this.editado = editado
            }
        },
        mounted() {
            this.dateFormatted = this.valor
        }
    }
</script>