import { defineStore } from 'pinia';

export const mantenedorStore = defineStore('mantenedor', {
  state: () => {
    return {
      // View debe ser en singular
      itemsMantenedor: [
        {
          id: 0,
          url: '/componentes',
          title: 'Componentes',
          titleForm: 'Componente',
          view: 'componente'
        },
        {
          id: 1,
          url: '/fases',
          title: 'Fases',
          titleForm: 'Fase',
          view: 'fase'
        },
        {
          id: 2,
          url: '/verificadores',
          title: 'Verificadores',
          titleForm: 'Verificador',
          view: 'verificador'
        },
        {
          id: 3,
          url: '/frecuencias',
          title: 'Frecuencias',
          titleForm: 'Frecuencia',
          view: 'frecuencia'
        },
        {
          id: 4,
          url: '/instrumentos',
          title: 'Instrumentos',
          titleForm: 'Instrumentos',
          view: 'instrumento'
        },
        {
          id: 5,
          url: '/regiones',
          title: 'Regiones',
          titleForm: 'Regiones',
          view: 'region'
        },
        {
          id: 6,
          url: '/comunas',
          title: 'Comunas',
          titleForm: 'Comunas',
          view: 'comuna'
        },
        {
          id: 7,
          url: '/usuarios',
          title: 'Usuarios',
          titleForm: 'Usuario',
          view: 'usuario'
        },
        {
          id: 8,
          url: '/estados',
          title: 'Estados',
          titleForm: 'Estados',
          view: 'estado'
        },
        {
          id: 9,
          url: '/codigo',
          title: 'Código DSS',
          titleForm: 'Código DSS',
          view: 'codigo'
        }
      ],
    }
  },
  actions: {
    getMantenedor(index) {
      return this.itemsMantenedor[index]
    }
  }
})