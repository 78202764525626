import axios from "axios";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_URL_API;
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFToken";

axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token');
    config.headers["Authorization"] = "Bearer " + token;
    return config;
});

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            // Redirige al usuario al login
            if (window.location.pathname != '/') {
                window.location.href = '/';
            }
        }
        return Promise.reject(error);
    }
);
  
