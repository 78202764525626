<template>
    <v-row justify="center">
        <load-data-component ref="load"></load-data-component>
        <snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>
        <v-dialog
            persistent
            v-model="dialog"
            width="90%"
        >
            <EnviarVisitaForm ref="enviarVisita" @envio-correo="notificacionEnvio"></EnviarVisitaForm>
            <v-card>
                <v-card-title class="mt-4">
                    <!--<span class="text-h5">Documento PDF</span>-->
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="descargar()">Descargar</v-btn>
                    <!--<v-btn color="yellow darken-1" text @click="$refs.pdf.print()">Imprimir</v-btn>-->
                    <!-- <v-btn color="blue darken-1" text @click="enviar()">Enviar</v-btn> -->
                    <v-btn color="red darken-1" text @click="dialog = false">Cerrar</v-btn>
                </v-card-title>
                <v-card-text>
                    <pdf  v-for="i in numPages"
                            :key="i"
                            :src="src"
                            :page="i">
                    </pdf>
                    <div hidden>
                        <pdf ref="pdf" :src="src"></pdf>
                    </div>
                </v-card-text>
            </v-card>
            <!--<snackbar-component :snackbar.sync="snackbar" :texto="texto" :estado="estado"></snackbar-component>-->
        </v-dialog>
    </v-row>
</template>

<script>
import pdf from 'vue-pdf'
import axios from "axios";
import EnviarVisitaForm from "@/views/formulario/EnviarVIsitaForm.vue"
export default {
    components: {
        pdf,
        EnviarVisitaForm
    },
    data () {
      return {
        dialog: false,
        page: 1,
        src: undefined,
		numPages: undefined,
        id: undefined,
        nombre: undefined,
        snackbar: false,
        texto: '',
        estado: 0
      }
    },
    methods: {
        open(base64, id, nombre) {
            this.dialog = true
            var loadingTask = pdf.createLoadingTask(base64);
            pdf.createLoadingTask(base64).promise.then(pdf => {
                this.numPages = pdf.numPages;
            });
            this.src = loadingTask
            this.id = id
            this.nombre = nombre
        },
        async descargar() {
            axios({
                url: "/documento/download/" + this.id,
                method: 'GET',
                responseType: 'blob',
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', this.nombre);
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
            }).catch(error => {
                console.error('Error al descargar el archivo:', error);
                this.snackbar = true;
                this.texto = 'Error al descargar documento'
                this.estado = 500
            });
        },
        enviar() {
            this.$refs.enviarVisita.open(this.id, this.nombre)
        },
        notificacionEnvio(response) {
            this.snackbar = true;
            this.texto = response.data.message
            this.estado = response.data.code
        }
    }
}
</script>
<style>
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 85%;
    margin-top: -40px !important;
}
</style>