<template>
    <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <v-file-input
                :label="label"
                dense
                v-model="model"
                @change="handleFileChange"
                :rules="computeFieldRules()"
            ></v-file-input>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            label: {
                type: String
            },
            valor: {
                type: File
            },
            required: {
                type: Boolean,
                default: false
            },
            segundoParametro: {
                type: Number
            },
            disabled: {
                type: Boolean,
                default: false
            }
        },
        data() {
            return {
                rules: {
                    required: (val) => (val || "").length > 0 || "Este campo es obligatorio.",
                },
                modelo: null
            }
        },
        methods: {
            getValue() {
                return this.modelo;
            },
            setValue(value) {
                this.modelo = value;
            },
            handleFileChange() {
                if(this.modelo) {
                    this.$emit('file-selected', this.modelo, this.segundoParametro);
                }
            },
            computeFieldRules() {
                const commonRules = this.required ? [this.rules.required] : [];
            }
        },
        computed: {
            model: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    this.modelo = newValue;
                }
            }
        },
        mounted() {
            this.model = this.valor
        },
    }
</script>