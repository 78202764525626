<template>
    <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <v-menu
            ref="menu"
            v-model="menu2"
            :close-on-content-click="false"
            :nudge-right="40"
            :return-value.sync="time"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
            >
            <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    dense
                    v-model="time"
                    :label="label"
                    prepend-icon="mdi-clock-time-four-outline"
                    v-bind="attrs"
                    v-on="on"
                    :rules="required ? [rules.required] : []"
                    :readonly="readonly"
                    @keydown="validateInput"
                    @input="handleInput"
                    :maxlength="5"
                ></v-text-field>
            </template>
            <v-time-picker
                v-if="menu2"
                v-model="time"
                full-width
                @click:minute="$refs.menu.save(time)"
            ></v-time-picker>
            </v-menu>
        </div>
    </div>
  </template>

<script>
export default {
    props: {
        label: {
            type: String,
            required: true
        },
        valor: {
            type: String
        },
        required: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: true
        }
    },
    data: vm => ({
        rules: {
            required: (val) => (val || "").length > 0 || "Este campo es obligatorio."
        },
        modelo: '',
        menu2: false,
        editado: false
    }),
    mounted() {
        this.time = this.valor
    },
    computed: {
        time: {
            get: function() {
                return this.modelo
            },
            set: function(newValue) {
                if ((this.time && this.time != '') && (newValue && newValue != '')) {
                    this.editado = true
                }
                this.modelo = newValue;
            }
        },
        numericValue() {
            if(this.time) {
                return this.time.replace(/\D/g, ''); // Filtrar solo números
            }
        },
    },
    methods: {
        getValue() {
            return this.modelo;
        },
        setValue(value) {
            this.modelo = value;
        },
        validateInput(event) {
            const allowedKeys = ["Backspace", "Tab", "ArrowLeft", "ArrowRight", "/"];
            if (!allowedKeys.includes(event.key) && !/^\d$/.test(event.key)) {
                event.preventDefault();
            }
        },
        handleInput() {
            const numericValue = this.numericValue;
            if (numericValue) {
                if (numericValue.length >= 2 && numericValue.length < 4) {
                this.time = `${numericValue.substr(0, 2)}:`;
                } else if (numericValue.length >= 4) {
                this.time = `${numericValue.substr(0, 2)}:${numericValue.substr(2, 2)}`;
                } else {
                this.time = numericValue;
                }
            }
        },
        getEditado() {
            return this.editado
        },
        setEditado(editado) {
            this.editado = editado
        }
    }
}
</script>