<template>
    <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <v-combobox
                :items="arreglo"
                dense
                :label="label"
                :item-text="itemText"
                :item-value="itemValue"
                v-model="model"
                @change="emitSelectedValue"
                :multiple="multiple"
                :key="contador"
                :rules="computeFieldRules()"
                :chips="multiple"
                :clearable="clearable"
                deletable-chips
                :hide-details="semaforo"
                @click:clear="emitClear"
                :disabled="disabled"
            >  
                <template v-slot:item="{ index, item }" v-if="editable">
                    <v-chip label small closable>
                        {{ item.nombre }}
                    </v-chip>
                    <v-spacer></v-spacer>
                    <v-list-item-action @click.stop>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    @click.stop.prevent="edit(item)"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                            </template>
                            <span>Editar</span>
                        </v-tooltip>
                    </v-list-item-action>
                </template>
            </v-combobox>
            <template v-if="semaforo">
                <p style="color: orange;">{{ mensajeSemaforo }}</p>
            </template>
            <TitularForm ref="titularRef" @reload="reload"></TitularForm>
        </div>
    </div>
</template>

<script>
    import TitularForm from "@/views/formulario/TitularForm.vue";
    import axios from "axios";
    export default {
        props: {
            url: {
                type: String
            },
            urlExterna: {
                type: String,
                default: null
            },
            label: {
                type: String,
                required: true
            },
            multiple: {
                type: Boolean,
                default: false
            },
            itemValue: {
                type: String,
                default: 'id'
            },
            itemText: {
                type: String,
                default: 'nombre'
            },
            valor: {
                type: [Object, String, Array]
            },
            valorMultiple: {
                type: Array
            },
            editable: {
                type: Boolean,
                default: false
            },
            type: {
                type: String,
                default: 'select'
            },
            required: {
                type: Boolean,
                default: false
            },
            data: {
                type: Array
            },
            selectedFirst: {
                type: Boolean,
                default: false
            },
            clearable: {
                type: Boolean,
                default: false
            },
            semaforo: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            name: {
                type: String,
                default: ''
            }
        },
        components: {
            TitularForm
        },
        data() {
            return {
                rules: {
                    required: (v) => v && v.id != undefined || "Este campo es obligatorio.",
                    semaforo: value => {
                        if (value != null && value.length == 0) {
                            this.mensajeSemaforo = 'Campo necesario para completar compromiso'
                        } else {
                            this.mensajeSemaforo = ''
                        }
                        return true
                    }
                },
                modelo: [],
                arreglo: [],
                contador: 0,
                estado: 0,
                mensajeSemaforo: '',
                editado: false
            }
        },
        methods: {
            getValue() {
                return this.modelo;
            },
            setValue(value) {
                this.modelo = value;
            },
            async getData() {
                if (this.data) {
                    this.arreglo = this.data
                } else {
                    const params = {
                        rowsPerPage: -1,
                        activo: 'y'
                    };
                    
                    if(this.urlExterna) {
                        fetch(this.urlExterna)
                        .then(response => response.json())
                        .then(data => {
                            this.arreglo = data
                        })
                    } else {
                        await axios.get(this.url, { params })
                        .then((response) => {
                            if (response.data) {
                                this.arreglo = response.data.body.items;
                                this.seleccionarPrimero(response.data.body.items.length)
                            }
                        }).catch((error) => {
                            console.error(error)
                        })
                    }
                }
            },
            seleccionarPrimero(cant) {
                if (cant > 0) {
                    if (cant > 1) {
                        this.$emit("primer-proyecto", false, null, true)
                    } else if (cant == 1) {
                        this.$emit("primer-proyecto", true, null, true)
                    }
                } else {
                    this.$emit("primer-proyecto", true, null, false)
                }
            },
            emitSelectedValue() {  
                if (!this.multiple) {
                    if (!this.arreglo.includes(this.model)) {
                        this.model = [];
                        return
                    }
                } else {
                    //this.model = this.model.filter(item => this.arreglo.includes(item));
                    this.$emit("selected-rca", this.model)
                }
                if (typeof this.modelo !== 'object' || this.modelo === null) {
                    this.modelo = ''
                }
                if (this.type == 'regiones' || this.type == 'comunas') {
                    this.$emit("selected-region", this.modelo);
                } else {
                    this.$emit("selected-value", this.modelo);
                }
            },
            emitClear() {
                this.$emit("clear-selected");
            },
            edit(item) {
                this.$refs.titularRef.open(item, false)
            },
            reload(value) {
                this.contador = value
                this.getData()
            },
            computeFieldRules() {
                if (this.semaforo) {
                    return [this.rules.semaforo]
                }
                return this.required ? [this.rules.required] : [];
            },
            getEditado() {
                return this.editado
            },
            setEditado(editado) {
                this.editado = editado
            }
        },
        computed: {
            model: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    let compare = ''
                    if (this.valor) {
                        compare = this.valor
                    } else if (this.valorMultiple) {
                        compare = this.valorMultiple
                    }
                    if (JSON.stringify(compare) != JSON.stringify(newValue)) {
                        if ((JSON.stringify(this.model) && JSON.stringify(this.model) != '') && (newValue && newValue != '')) {
                            this.editado = true
                        }
                    }
                    /*if (this.multiple) {
                        newValue.forEach(element => {
                            const index = newValue.indexOf(element);
                            if (!this.arreglo.some(e => e.nombre === element.nombre)) {
                                newValue.splice(index, 1);
                            }
                        });
                    }*/
                    this.modelo = newValue;
                }
            },
        },
        mounted() {
            this.getData()
            if (this.valor) {
                this.model = this.valor
            }
            if (this.valorMultiple) {
                this.model = this.valorMultiple
            }
        },
        updated() {
            if (this.selectedFirst && this.arreglo && this.arreglo.length == 1) {
                this.modelo = this.arreglo[0]
                if (this.name == 'UFHome') {
                    this.$emit("primer-proyecto", true, this.modelo, true)
                }
            } else {
                if (this.name == 'UFHome') {
                    this.$emit("primer-proyecto", false, true)
                }
            }
        },
    }
</script>
