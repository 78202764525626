<template>
    <v-col>
        <LoadData ref="load"></LoadData>
        <v-row class="fill-height">
            <v-col>
            <v-sheet height="64">
                <v-toolbar
                flat
                >
                <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                >
                    Hoy
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="prev"
                >
                    <v-icon small>
                    mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-btn
                    fab
                    text
                    small
                    color="grey darken-2"
                    @click="next"
                >
                    <v-icon small>
                    mdi-chevron-right
                    </v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                    outlined
                    class="mr-4 ml-6"
                    color="grey darken-2"
                    @click="newEvent"
                >
                    Agregar evento
                </v-btn>
                <v-menu
                    bottom
                    right
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                    >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right>
                        mdi-menu-down
                        </v-icon>
                    </v-btn>
                    </template>
                    <v-list>
                    <v-list-item @click="type = 'day'">
                        <v-list-item-title>Dia</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                        <v-list-item-title>Semana</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                        <v-list-item-title>Mes</v-list-item-title>
                    </v-list-item>
                    </v-list>
                </v-menu>
                </v-toolbar>
            </v-sheet>
            <v-sheet :height="heightCal">
                <v-calendar
                    ref="calendar"
                    v-model="focus"
                    color="primary"
                    :events="events"
                    :event-color="getEventColor"
                    :type="type"
                    @click:event="showEvent"
                    @click:more="viewDay"
                    @click:date="viewDay"
                    locale="es-cl"
                ></v-calendar>
                <v-menu
                    v-model="selectedOpen"
                    :close-on-content-click="false"
                    :activator="selectedElement"
                    offset-x
                >
                <v-card
                    color="grey lighten-4"
                    min-width="350px"
                    flat
                >
                    <v-toolbar
                        :color="selectedEvent.color"
                        dark
                    >
                        <v-btn icon @click="editEvento(selectedEvent)">
                            <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                        <v-btn v-if="selectedEvent.visita ? false : true && selectedEvent.createdby == createdby ? true : false" icon @click="deleteEvento(selectedEvent)">
                            <v-icon>mdi-delete</v-icon>
                        </v-btn>
                        <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                        <v-spacer></v-spacer>
                        <!--<v-btn icon>
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>-->
                    </v-toolbar>
                    <v-card-text>
                    <span v-html="selectedEvent.details"></span>
                    </v-card-text>
                    <v-card-actions>
                    <v-btn
                        text
                        color="secondary"
                        @click="selectedOpen = false"
                    >
                        Cerrar
                    </v-btn>
                    </v-card-actions>
                </v-card>
                </v-menu>
            </v-sheet>
            </v-col>
        </v-row>
        <NuevoEvento ref="nuevoEventoRef" @refresh-calendar="refresh"></NuevoEvento>
        <SnackBar :snackbar.sync="snackbar" :texto="texto" :estado="estado"></SnackBar>
    </v-col>
</template>

<script>
    import { commonStore } from '@/store/CommonStore';
    import LoadData from '../LoadData.vue';
    import NuevoEvento from './NuevoEvento.vue'
    import axios from "axios";
    import { perfilStore } from "@/store/PerfilStore"
    import SnackBar from '../SnackBar.vue';

    export default {
        props: {
            heightCal: {
                type: String,
                default: '600px'
            }
        },
        components: {
            NuevoEvento,
            LoadData,
            SnackBar
        },
        data: () => ({
            focus: '',
            type: 'month',
            typeToLabel: {
                month: 'Mes',
                week: 'Semana',
                day: 'Dia'
            },
            selectedEvent: {},
            selectedElement: null,
            selectedOpen: false,
            events: [],
            //colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
            colors: ['green'],
            commonStore: commonStore(),
            snackbar: false,
            texto: '',
            estado: 0,
            perfilStore: perfilStore(),
            createdby: 0
        }),
        mounted() {
            this.$refs.calendar.checkChange();
            this.getEventos()
            this.createdby = this.perfilStore.decryptData(localStorage.getItem('id'))
        },
        methods: {
            viewDay({ date }) {
                this.focus = date;
                this.type = 'day';
            },
            getEventColor(event) {
                return event.color;
            },
            setToday() {
                this.focus = '';
            },
            prev() {
                this.$refs.calendar.prev();
            },
            next() {
                this.$refs.calendar.next();
            },
            showEvent({ nativeEvent, event }) {
                const open = () => {
                    this.selectedEvent = event;
                    this.selectedElement = nativeEvent.target;
                    requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true));
                };
                if (this.selectedOpen) {
                    this.selectedOpen = false;
                    requestAnimationFrame(() => requestAnimationFrame(() => open()));
                }
                else {
                    open();
                }
                nativeEvent.stopPropagation();
            },
            rnd(a, b) {
                return Math.floor((b - a + 1) * Math.random()) + a;
            },
            newEvent() {
                this.$refs.nuevoEventoRef.open()
            },
            async getEventos() {
                this.$refs.load.open();
                const params = {
                    user_id: this.perfilStore.decryptData(localStorage.getItem('id'))
                };
                await axios.get("agenda", { params })
                .then(response => {
                    const events = [];
                    const result = response.data.body.items
                    result.forEach((element) => {
                        const detalle = "Hora inicio: " + this.commonStore.nullOrEmpty(element.hora_inicio) + "<br>" + "Hora fin: " + this.commonStore.nullOrEmpty(element.hora_fin) + "<br><br>" + "Descripción:<br>" + this.commonStore.nullOrEmpty(element.descripcion)
                        events.push({
                            name: element.titulo,
                            start: new Date(element.fecha + (element.hora_inicio ? ' ' + element.hora_inicio : '')),
                            end: new Date(element.fecha + (element.hora_fin ? ' ' + element.hora_fin : '')),
                            details: detalle,
                            hora_inicio: element.hora_inicio,
                            hora_fin:element.hora_fin,
                            color: element.visita ? this.getColor(element.visita.auditoria) : 'green',
                            lugar: element.lugar,
                            descripcion: element.descripcion,
                            fecha: element.fecha,
                            id: element.id,
                            timed: (element.hora_inicio || element.hora_fin) ? true : false,
                            createdby: element.createdby,
                            visita: element.visita ? true : false
                        })
                    })
                    this.events = events;
                    this.$refs.load.close();
                });
            },
            refresh(response) {
                this.getEventos()
                this.snackbar = true;
                this.texto = response.data.message
                this.estado = response.data.code
            },
            editEvento(item) {
                this.$refs.nuevoEventoRef.open(item)
            },
            deleteEvento(item){
                this.selectedOpen = false
                axios.post('eliminar/evento/' + item.id).then(response => {
                    this.refresh(response)
                })
            },
            getColor(esAuditoria) {
                if (esAuditoria == 'y') {
                    return 'orange'
                } else {
                    return 'green'
                }
            }
        },
    }
</script>

