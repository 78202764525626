<template>
    <v-row justify="end" class="mr-1">
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
            <LoadData ref="load"></LoadData>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="text-h5">Contacto</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelar">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <!-- <v-card-title>
                <span class="text-h5">Contacto</span>
            </v-card-title> -->
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                    <v-container>
                        <v-row>
                            <v-col cols="12">
                                <text-field-component label="Nombre *" ref="cfNombre" :valor="nombre" required></text-field-component>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <text-field-component label="Email" ref="cfEmail" type="email" :valor="email"></text-field-component>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <text-field-component label="Teléfono" ref="cfTelefono" :valor="telefono"></text-field-component>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <small v-if="!disabled">* Indica campo obligatorios</small>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="guardarContacto"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
</template>

<script>
    import axios from "axios";
    import LoadData from '@/components/LoadData.vue'
    import { EventBus } from "@/event/event"

    export default {
        components: {
            LoadData
        },
        data: () => ({  
            dialog: false,
            valid: true,
            nombre: '',
            telefono: '',
            email: '',
            disabled: false,
            item: [],
        }),
        methods: {
            async guardarContacto() {
                if (this.$refs.form.validate()) {
                    this.$refs.load.open();
                    const data = {
                        nombre: this.$refs.cfNombre.getValue(),
                        email: this.$refs.cfEmail.getValue(),
                        telefono: this.$refs.cfTelefono.getValue(),
                        titular_id: this.item.titular_id
                    }
                    if (this.item.contacto_id) {
                        await axios.put('contactos/' + this.item.contacto_id, data)
                        .then(response => {
                            this.dialog = false
                            EventBus.$emit('reload-contactos')
                            this.refreshEditado()
                            this.$refs.load.close();
                        })
                        .catch( error => {
                            console.error(error)
                        })
                    } else {
                        await axios.post('contactos', data)
                        .then(response => {
                            this.dialog = false
                            EventBus.$emit('reload-contactos')
                            this.refreshEditado()
                            this.$refs.load.close();
                        })
                        .catch( error => {
                            console.error(error)
                        })
                    }
                }
            },
            cancelar() {
                if (this.editado()) {
                    this.$swal.fire({
                        title: "¿Desea guardar los cambios efectuados?",
                        icon: 'warning',
                        showDenyButton: true,
                        showCancelButton: true,
                        confirmButtonText: "Guardar",
                        denyButtonText: 'No guardar',
                        cancelButtonText: "Cancelar",
                        confirmButtonColor: "#4caf50",
                        allowOutsideClick: false,
                        showCloseButton: true
                    }).then((result) => { 
                        if( result.isConfirmed) {
                            this.guardarContacto()
                            if (this.$refs.form.validate()) {
                                this.dialog = false
                            }
                        } else if (result.isDenied) {
                            this.$refs.form.reset();
                            this.$refs.form.resetValidation();
                            this.dialog = false
                            this.refreshEditado()
                        }
                    })
                } else {
                    this.$refs.form.reset();
                    this.$refs.form.resetValidation();
                    this.dialog = false
                }
            },
            open(item, disabled) {
                if (item) {
                    this.item = item
                    if (item.contacto_id) {
                        if (this.$refs.form) {
                            this.$refs.cfNombre.setValue(item.nombre)
                            this.$refs.cfTelefono.setValue(item.telefono)
                            this.$refs.cfEmail.setValue(item.email)
                        } else {
                            this.nombre = item.nombre
                            this.telefono = item.telefono
                            this.email = item.email
                        }
                    }
                }
                this.disabled = disabled
                this.dialog = true
            },
            refreshEditado() {
                this.$refs.cfNombre.setEditado(false)
                this.$refs.cfEmail.setEditado(false)
                this.$refs.cfTelefono.setEditado(false)
            },
            editado() {
                if (this.$refs.cfNombre.getEditado() ||
                    this.$refs.cfEmail.getEditado() ||
                    this.$refs.cfTelefono.getEditado()) {
                    return true
                } else {
                    return false
                }
            }
        }
    }
</script>

<style scoped>
  .dialog-toolbar {
    position: sticky;
    top: 0;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
</style>