<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card>
            <LoadData ref="load"></LoadData>
            <v-card-title>
                <span class="text-h5">Evento</span>
            </v-card-title>
            <v-card-text>
                <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
                    <v-container>
                        <v-row>
                            <v-col cols="6">
                                <text-field-component label="Titulo" :valor="titulo" ref="tfTitulo" required></text-field-component>
                            </v-col>
                            <v-col cols="6">
                                <text-field-component label="Lugar" :valor="lugar" ref="tfLugar"></text-field-component>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="4">
                                <date-picker-component label="Fecha" :valor="fecha" required ref="dpFecha"></date-picker-component>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <text-field-component type="time" label="Hora inicio" :valor="hora_inicio" ref="tfHoraInicio"></text-field-component>
                            </v-col>
                            <v-col cols="12" sm="6" md="4">
                                <text-field-component type="time" label="Hora fin" :valor="hora_fin" ref="tfHoraFin"></text-field-component>
                            </v-col>
                        </v-row>
                        <text-area-component label="Descripción" :valor="descripcion" ref="taDescripcion" :counterValue="5000"></text-area-component>
                    </v-container>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    class="ma-2"
                    large
                    @click="cancelar"
                >
                    Cerrar
                </v-btn>
                <v-btn
                    v-if="!disabled"
                    class="ma-2"
                    dark
                    color="cyan"
                    large
                    @click="guardar"
                >
                    <v-icon left>
                        mdi-content-save
                    </v-icon>
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";
import LoadData from "../LoadData.vue";
export default {
    components: { 
        LoadData
    },
    data: () => ({
        disabled: false,
        dialog: false,
        valid: true,
        titulo: '',
        lugar: '',
        descripcion: '',
        fecha: '',
        hora_inicio: '',
        hora_fin: '',
        id: null
    }),
    methods: {
        open(item, disabled) {
            this.disabled = disabled
            if (item) {
                this.id = item.id
                if (!this.$refs.form) {
                    this.titulo = item.titulo
                    this.lugar = item.lugar
                    this.descripcion = item.descripcion
                    this.fecha = item.fecha
                    this.hora_inicio = item.hora_inicio
                    this.hora_fin = item.hora_fin
                } else {
                    this.$refs.tfTitulo.setValue(item.titulo)
                    this.$refs.tfLugar.setValue(item.lugar)
                    this.$refs.taDescripcion.setValue(item.descripcion)
                    this.$refs.dpFecha.setValue(item.fecha)
                    this.$refs.tfHoraInicio.setValue(item.hora_inicio)
                    this.$refs.tfHoraFin.setValue(item.hora_fin)
                }
            } else {
                this.id = null
            }
            this.dialog = true;
        },
        cancelar() {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.dialog = false;
        },
        guardar() {
            if (this.$refs.form.validate()) {
                this.$refs.load.open();
                const data = {
                    titulo: this.$refs.tfTitulo.getValue(),
                    lugar: this.$refs.tfLugar.getValue(),
                    descripcion: this.$refs.taDescripcion.getValue(),
                    fecha: this.$refs.dpFecha.getValue(),
                    hora_inicio: this.$refs.tfHoraInicio.getValue(),
                    hora_fin: this.$refs.tfHoraFin.getValue()
                };
                if (this.id) {
                    axios.put('agenda/' + this.id, data)
                        .then((response) => {
                            this.$refs.load.close();
                            this.$emit("refresh-calendar", response)
                            this.cancelar()
                    }).catch((error) => {
                        console.error(error);
                        this.$refs.load.close();
                        this.$emit("refresh-calendar", response)
                        this.cancelar()
                    });
                } else {
                    axios.post('agenda', data)
                        .then((response) => {
                            this.$refs.load.close();
                            this.$emit("refresh-calendar", response)
                            this.cancelar()
                    }).catch((error) => {
                        console.error(error);
                        this.$refs.load.close();
                        this.$emit("refresh-calendar", response)
                        this.cancelar()
                    });
                }
            }
        },
    }
}
</script>