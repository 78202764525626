<template>
    <div class="text-center ma-2">
        <v-snackbar v-model="localSnackbar" :color="color" top :style="style_" shaped :height="70" :timeout="timeout">
            {{ texto }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    color="white"
                    text
                    v-bind="attrs"
                    @click="localSnackbar = false"
                >
                    Cerrar
                </v-btn>
            </template>
        </v-snackbar>
    </div>
</template>
  
<script>
    export default {
        name: 'SnackBarComponent',
        props: {
            snackbar: {
                type: Boolean,
                default: false,
            },
            estado: {
                type: Number
            },
            texto: {
                type: String
            },
            style_: {
                type: String,
                default: 'margin-top: 100px;'
            }
        },
        data() {
            return {
                localSnackbar: this.snackbar,
                color: '#9E9E9E',
                timeout: 5000
            };
        },
        watch: {
            snackbar(newVal) {
                this.localSnackbar = newVal;
            },
            localSnackbar(newVal) {
                this.$emit('update:snackbar', newVal);
            },
        },
        beforeUpdate() {
            this.timeout = 5000
            switch (this.estado) {
                case 200:
                    this.color = 'green'
                    break;
                case 201:
                    this.color = 'green'
                    break;
                case 204:
                    this.color = '#FF6F00'
                    break;
                case 500:
                    this.color = '#D50000'
                    this.timeout = 0
                    break;
                case 401:
                    this.color = '#D50000'
                    this.timeout = 0
                    break;
                case 409:
                    this.color = '#ef7b1c'
                    break;
            }
        },
        created() {
            this.timeout = 5000
            switch (this.estado) {
                case 200:
                    this.color = 'green'
                    break;
                case 201:
                    this.color = 'green'
                    break;
                case 204:
                    this.color = '#FF6F00'
                    break;
                case 500:
                    this.color = '#D50000'
                    this.timeout = 0
                    break;
                case 409:
                    this.color = '#ef7b1c'
                    break;
            }
        },
    };
</script>