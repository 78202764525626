<template>
    <v-dialog v-model="dialog" max-width="450" persistent>
        <v-card>
            <v-toolbar flat class="dialog-toolbar">
                <v-toolbar-title class="justify-center">{{title}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon @click="cancelDialog">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-card-title>
            </v-card-title>
            <v-card-text class="text-center">
                <template v-if="showDocument">
                    <embed :type="tipo" :src="urlDocumento" style="width: 100%; height: 700px;">
                </template>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
    import axios from 'axios';
    export default { 
        data() {
            return {
                dialog: false,
                item: null,
                title: 'Visualización de documentos',
                urlDocumento: null,
                showDocument: false,
                tipo: 'image/jpg'
            }
        },
        methods: {
            open(item) {
                this.dialog = true
                this.item = item
                this.obtenerDocumento(item)
            },  
            obtenerDocumento(item) {
                const params = {
                    'id': item.id
                }
                axios({
                    url: '/mostrar-documento',
                    method: 'GET',
                    params: params
                }).then(response => {
                    if (response.data.body.isPdf) {
                        this.tipo = 'application/pdf'
                    }
                    this.urlDocumento = response.data.body.items
                    this.showDocument = true
                })
            },
            cancelDialog () {
                this.dialog = false
            }
        }
    }
</script>