<template>
    <div class="row d-flex flex-wrap" role="group">
        <div class="col">
            <template>
                <v-checkbox
                    :label="label"
                    v-model="model"
                ></v-checkbox>
            </template>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            label: {
                type: String
            },
            valor: {
                type: Boolean
            }
        },
        data() {
            return {
                modelo: null,
                editado: false
            }
        },
        methods: {
            getValue() {
                return this.modelo;
            },
            setValue(value) {
                this.modelo = value;
            },
            getEditado() {
                return this.editado
            },
            setEditado(editado) {
                this.editado = editado
            }
        },
        computed: {
            model: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    if (this.model != null && newValue != null) {
                        this.editado = true
                    }
                    this.modelo = newValue;
                }
            }
        },
        mounted() {
            this.model = this.valor
        }
    }
</script>