<template>
  <v-container>
    <div class="page-title-box d-sm-flex align-items-center justify-content-between">
      <h4>Bienvenido</h4>
    </div>
    <v-row>
      <v-col cols="4">
        <select-component :label="labelSelectTitular" ref="sTitular" :url="'/titular/proyecto/' + proyectoId + '/' + user_id" @selected-value="handleTitularSeleccionado" @clear-selected="clearSelected" :valor="titular" :key="titularKey" :selectedFirst="selectedFirstTitular" :clearable="true" :disabled="disabledTitular"></select-component>
      </v-col>
      <v-col cols="4">
        <select-component :label="labelSelectProyecto" ref="sProyecto" :url="'/proyecto/titular/' + titularId" @selected-value="handleProyectoSeleccionado" @clear-selected="clearSelected" :valor="proyecto" :key="proyectoKey" :clearable="true" @primer-proyecto="primerProyecto" :selectedFirst="selectedFirstProyecto" :disabled="disabledUF" name="UFHome"></select-component>
      </v-col>
      <v-col v-if="mostrarDatos">
        <a @click="ficha()">Ir a Ficha Unidad Fiscalizable...</a>
      </v-col>
    </v-row>
    <v-row v-if="mostrarDatos">
      <v-col sm="4" style="max-height: 600px;">
        <v-card max-width="820" max-heigth="30px" elevation="10">
          <v-card-text style="text-align: center;">
            <BarChart 
              :label="['Cumple', 'No Cumple', 'Observado', 'No verificado']"
              :backgroundColors="['#ADD8E6', '#FFCCCB', '#FFA07A', '#D3D3D3']"
              :datos="valoresChart"
              :key="keyChart"
              :proyecto="proyectoSeleccionado"
            ></BarChart>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col sm="8" class="mt-1">
        <v-row>
          <v-col sm="4">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Compromisos cargados:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ compromisos }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="4">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Visitas programadas:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ visitasProgramadas }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col sm="4">
            <v-card max-height="80" elevation="10">
              <v-card-text style="text-align: center; padding-top: 0px;">
                <div>Visitas Finalizadas:</div>
                <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                  {{ visitasFinalizadas }}
                </p>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card max-heigth="30px" elevation="10">
              <v-card-text style="text-align: center;">
                <Calendario heightCal="270px"></Calendario>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col>
        <v-card max-heigth="30px" elevation="10">
          <v-card-title>
            Eventos
          </v-card-title>
          <v-card-text style="text-align: center;">
            <table-component :headers="headers" :url="'eventos/'+proyectoSeleccionado.id" view="ficha-evento" labelSearch="Búsqueda de eventos" :key="contador" :showAdd="false"></table-component>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <template v-if="mostrarDatosTitular && perfilStore.decryptData(perfil_id) == perfilStore.titular">
      <v-row class="justify-center">
        <v-col sm="9" class="mt-1">
          <v-row class="justify-center">
            <v-col sm="3">
              <v-card max-height="80" elevation="10">
                <v-card-text style="text-align: center; padding-top: 0px;">
                  <div>Unidades Fiscalizables:</div>
                  <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                    {{ cantidadUf }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="3">
              <v-card max-height="80" elevation="10">
                <v-card-text style="text-align: center; padding-top: 0px;">
                  <div>RCAs:</div>
                  <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                    {{ cantidadRcas }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col sm="3">
              <v-card max-height="80" elevation="10">
                <v-card-text style="text-align: center; padding-top: 0px;">
                  <div>Compromisos:</div>
                  <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                    {{ cantidadCompromisos }}
                  </p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row class="justify-center">
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Cumple:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCumple }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadCumple !== null" style="color: green">
                {{ calcularPorcentaje(cantidadCumple, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>No Cumple:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadNoCumple }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadNoCumple !== null" style="color: green">
                {{ calcularPorcentaje(cantidadNoCumple, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Otros estados:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCompromisosOtros }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadCompromisosOtros !== null" style="color: green">
                {{ calcularPorcentaje(cantidadCompromisosOtros, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col sm="2">
          <v-card max-height="100" elevation="10">
            <v-card-text style="text-align: center; padding-top: 0px;">
              <div>Sin revisión:</div>
              <p class="text-h5 text--primary" style="margin-bottom: 0px;">
                {{ cantidadCompromisosNull }}
              </p>
              <label v-if="cantidadCompromisos > 0 && cantidadCompromisosNull !== null" style="color: green">
                {{ calcularPorcentaje(cantidadCompromisosNull, cantidadCompromisos) + '%' }}
              </label>
              <label v-else style="color: green">Cargando...</label>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <!-- <v-row>
      <v-col cols="12">
        <v-card max-heigth="400px" max-width="1680px" elevation="10">
          <v-card-text>
            <l-map style="height: 30em; width: auto; z-index: 1;" :zoom="zoom" :center="center">
              <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
              <v-marker-cluster :options="markerClusterOptions">
                <l-marker v-for="marker in markers" :key="marker.id" :lat-lng="marker.latLng">
                  <l-popup>{{ marker.popupContent }}</l-popup>
                </l-marker>
              </v-marker-cluster>
            </l-map>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row> -->
</v-container>
</template>

<script>
  import Calendario from "@/components/calendario/Calendario.vue";
  import BarChart from "@/components/chart/BarChart.vue";
  import axios from "axios";
  import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
  import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster'
  import "leaflet/dist/leaflet.css";
  import proj4 from 'proj4'
  import { perfilStore } from "@/store/PerfilStore";
  export default {
    components: { 
      BarChart, 
      Calendario, 
      LMap,
      LTileLayer,
      LMarker,
      LPopup, 
      'v-marker-cluster': Vue2LeafletMarkerCluster
    },
    data: () => ({
      reveal: false,
      compromisos: 0,
      visitasProgramadas: 0,
      visitasFinalizadas: 0,
      zoom: 4,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "© OpenStreetMap contributors",
      markers: [],
      markerClusterOptions: {
        // Opciones del agrupamiento de marcadores
        spiderfyOnMaxZoom: true,
        showCoverageOnHover: true,
        zoomToBoundsOnClick: true,
      },
      proyecto: null,
      titular: null,
      proyectoSeleccionado: null,
      proyectoId: 0,
      mostrarDatos: false,
      valoresChart: [],
      keyChart: 0,
      contador: 0,
      headers: [
        { text: 'Nombre', value: 'titulo', filtro:false },
        { text: 'Descripción', value: 'descripcion' },
        { text: 'Fecha evento', value: 'fecha', filtro:false },
        { text: 'Estado', value: 'visita.estado.nombre', filtro:false, width: '100px' },
        { text: 'Hora inicio', value: 'hora_inicio', filtro:false },
        { text: 'Hora fin', value: 'hora_fin', filtro:false },
        { text: 'Lugar', value: 'lugar', filtro:false },
        { text: 'Acciones', value: 'actions', filtro:false, align: 'center'},
      ],
      titularId: 0,
      titularKey: 0,
      selectedFirstTitular: false,
      proyectoKey: 0,
      mostrarDatosTitular: false,
      perfilStore: perfilStore(),
      perfil_id: null,
      disabledTitular: false,
      selectedFirstProyecto: false,
      labelSelectTitular: 'Seleccione un titular',
      labelSelectProyecto: 'Seleccione Unidad Fiscalizable',
      cantidadUf: 0,
      cantidadRcas: 0,
      cantidadCompromisos: 0,
      cantidadCumple: 0,
      cantidadNoCumple: 0,
      cantidadCompromisosOtros: 0,
      cantidadCompromisosNull: 0,
      disabledUF: false,
      user_id: 0
    }),
    methods: {
      async handleTitularSeleccionado(value) {
        if(value) {
          if(value != null) {
            this.titularId = value.id
            this.proyectoKey++
          } else {
            this.titularId = 0
            this.proyectoKey++
            this.titularKey++
          }
        }
      },
      async handleProyectoSeleccionado(value) {
        if (value) {
          if(value != null) {
            this.proyectoSeleccionado = value
            this.proyectoId = this.proyectoSeleccionado.id
            const params = {
              id: this.proyectoId
            }
            await axios.get('estado/compromiso', {params}).then((response) => {
              this.valoresChart = response.data.body.items
              this.getProyecto(this.proyectoId)
              this.mostrarDatos = true
              this.mostrarDatosTitular = false
            })
            this.keyChart++
            ++this.contador
            this.selectedFirstTitular = true
            this.titularKey++
          } else {
            this.proyectoId = 0
            this.selectedFirstTitular = false
            this.titularKey++
          }
        }
      },
      ficha() {
        if (this.proyectoSeleccionado) {
          this.$router.push({name: 'ficha', query: {proyecto: this.proyectoSeleccionado.id}})
        }
      },
      async getProyecto(proyecto_id) {
        const params = {
          id: proyecto_id
        }
        await axios.get("/dashboard/contador", {params})
        .then((response) => {
          this.compromisos = response.data.body.items.compromisos
          this.visitasProgramadas = response.data.body.items.visitasProgramadas
          this.visitasFinalizadas = response.data.body.items.visitasFinalizadas
        }).catch((error) => {
            console.error(error)
        })
      },
      async coordenadas() {
        await axios.get("/proyectos/coordenadas")
        .then((response) => {
          const arreglo = response.data.body.items
          let aux = 1;
          arreglo.forEach(element => {
            switch (element.tipo_coordenada) {
              case 'DIR':
              const nominatimUrl = 'https://nominatim.openstreetmap.org/search';
              const url = `${nominatimUrl}?format=json&addressdetails=1&q=${encodeURIComponent(element.direccion)}&countrycodes=CL`;

              // Realiza la solicitud CORS directamente desde el navegador
                fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.length > 0) {
                        const firstResult = data[0];
                        const latitude = parseFloat(firstResult.lat)
                        const longitude = parseFloat(firstResult.lon)
                        this.markers.push({id: aux, latLng:[latitude, longitude], popupContent: element.nombre})
                    } else {
                        console.error('No se encontraron coordenadas para la dirección proporcionada.');
                    }
                }).catch(error => {
                    console.error('Error al obtener coordenadas:', error);
                });
                break;
              case 'UTM':
                const latitud = parseFloat(element.latitud.replace(',', '.'));
                const longitud = parseFloat(element.longitud.replace(',', '.'));
                proj4.defs("EPSG:32718", "+proj=utm +zone=18 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
                proj4.defs("EPSG:32719", "+proj=utm +zone=19 +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs");
                let zonaUtm = "EPSG:32719"
                if (element.zona == 18) {
                    zonaUtm = "EPSG:32718"
                }
                let latLng = proj4(zonaUtm, "EPSG:4326", [latitud, longitud]);
                this.markers.push({id: aux, latLng:[latLng[1], latLng[0]], popupContent: element.nombre})
                aux++
                break;
              case 'DEC':
                const latitude = parseFloat(element.latitud)
                const longitude = parseFloat(element.longitud)
                this.markers.push({id: aux, latLng:[latitude, longitude], popupContent: element.nombre})
                aux++
                break;
            }
          });
        }).catch((error) => {
          console.error(error)
        })
      },
      async getCoordinatesFromAddress(address) {
        const nominatimUrl = 'https://nominatim.openstreetmap.org/search';
        const url = `${nominatimUrl}?format=json&addressdetails=1&q=${encodeURIComponent(address)}&countrycodes=CL`;

        // Realiza la solicitud CORS directamente desde el navegador
        fetch(url)
        .then(response => response.json())
        .then(data => {
            if (data.length > 0) {
                const firstResult = data[0];
                const latitude = parseFloat(firstResult.lat)
                const longitude = parseFloat(firstResult.lon)
                return [latitude, longitude]
            } else {
                console.error('No se encontraron coordenadas para la dirección proporcionada.');
            }
        }).catch(error => {
            console.error('Error al obtener coordenadas:', error);
        });
      },
      clearSelected() {
        if(this.perfilStore.decryptData(localStorage.getItem('perfil_id'))!= this.perfilStore.titular) {
          this.proyectoId = 0
          this.titularId = 0
          this.titularKey++
          this.proyectoKey++
          this.mostrarDatos = false
        } else {
          this.obtenerValoresTarjetasTitular()
          this.proyectoKey++
          this.mostrarDatos = false
          this.mostrarDatosTitular = true
        }
      },
      async obtenerTitular() {
        await axios.get('/titular/asignado').then((response) => {
          this.$refs.sTitular.setValue(response.data.body.items[0])
          this.titular = response.data.body.items[0]
          this.titularId = response.data.body.items[0].id
          this.proyectoKey++
        }).catch((error) => {
          console.error(error)
        })
      },
      primerProyecto(value, model, ok) {
        if (ok) {
          if (this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular) {
            this.selectedFirstProyecto = value
            if (value) {
              this.disabledUF = true
              this.labelSelectProyecto = ''
              this.labelSelectTitular = ''
              if (model) {
                this.handleProyectoSeleccionado(model)
              }
            } else {
              this.labelSelectProyecto = 'Seleccione Unidad Fiscalizable'
              this.labelSelectTitular = ''
              this.mostrarDatosTitular = true
            }
          }
        }
      },
      async obtenerValoresTarjetasTitular() {
        await axios.get('/informacion/uf/titular').then(response => {
          this.cantidadUf = response.data.body.items.cantidadUfs
          this.cantidadRcas = response.data.body.items.cantidadRcas
          this.cantidadCompromisos = response.data.body.items.cantidadCompromisos
          this.cantidadCumple = response.data.body.items.cantidadCompromisoCumple
          this.cantidadNoCumple = response.data.body.items.cantidadCompromisoNoCumple
          this.cantidadCompromisosOtros = response.data.body.items.cantidadCompromisosOtros
          this.cantidadCompromisosNull = response.data.body.items.cantidadCompromisosNull
        }).catch((error) => {
          console.error(error)
        })
      },
      calcularPorcentaje(parte, total){
        let porcentaje = ((parte/total) * 100).toFixed(2)
        if (porcentaje == 0) {
          return 0
        }
        return porcentaje
      }
    },
    mounted() {
      /* this.coordenadas(); */
      this.perfil_id = localStorage.getItem('perfil_id')
      if(this.perfilStore.decryptData(this.perfil_id) == this.perfilStore.titular) {
        this.disabledTitular = true
        this.obtenerTitular()
        this.obtenerValoresTarjetasTitular()
      }
      const parametro = this.$route.params.parametro;
      if (parametro) {
        this.$refs.sProyecto.setValue(parametro);
        this.handleSelectedValue(parametro)
      }
    },
    beforeMount() {
      this.user_id = localStorage.getItem('id')
    }
  }
</script>

<style scoped>
@import "~leaflet.markercluster/dist/MarkerCluster.css";
@import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
.v-sheet.v-card {
  border-radius: 15px !important
}
.rotated-image {
    transform: rotate(-94deg); /* Puedes ajustar el ángulo de rotación según tus necesidades */
  }
</style>