<template>
    <v-container class="px-0" fluid>
        <v-switch
            class="v-input--reverse"
            :hint=getStatusSwitch()
            persistent-hint
            v-model="switch1"
            :label="label"
        ></v-switch>
    </v-container>
</template>

<script>
    export default {
        props: {
            label: {
                type: String
            },
            valor: {
                type: Boolean,
                default: true
            }
        },
        data () {
            return {
                modelo: ''
            }
        },
        methods: {
            getStatusSwitch() {
                if (this.switch1) {
                    return "Activo"
                } else {
                    return "Inactivo"
                }
            },
            setValue(value) {
                this.switch1 = value
            },
            getValue() {
                if (this.switch1) {
                    return 'y'
                } else {
                    return 'n'
                }
            }
        },
        computed: {
            switch1: {
                get: function() {
                    return this.modelo
                },
                set: function(newValue) {
                    this.modelo = newValue;
                }
            }
        },
        mounted() {
            this.switch1 = this.valor
        }
    }
</script>

<style>
    .v-input--reverse .v-input__slot {
        flex-direction: row-reverse;
        justify-content: flex-end;
        .v-application--is-ltr & {
            .v-input--selection-controls__input {
                margin-right: 0;
                margin-left: 8px;
            }
        }
        .v-application--is-rtl & {
            .v-input--selection-controls__input {
                margin-left: 0;
                margin-right: 8px;
            }
        }
    }
</style>