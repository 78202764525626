import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "@/plugins/vuetify";
import "./axios";
import { createPinia, PiniaVuePlugin } from 'pinia'
import TableComponent from '@/components/TableComponent.vue'
import TextField from '@/components/TextField.vue'
import SelectComponent from "@/components/Select.vue";
import SwitchComponent from "@/components/SwitchComponent.vue";
import DatePickerComponent from "@/components/Datepicker.vue"
import TextAreaComponent from "@/components/TextAreaComponent.vue"
import SnackBarComponent from "@/components/SnackBar.vue"
import FileInputComponent from "@/components/FileInput.vue"
import LoadData from '@/components/LoadData.vue'
import ViewPdfComponent from '@/components/ViewPdfComponent.vue'
import TimepickerComponent from '@/components/TimepickerComponent.vue'
import DialogYesNoComponent from "@/components/DialogYesNoComponent.vue";
import CheckBoxComponent from "@/components/CheckBoxComponent.vue";
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon } from "leaflet";
/* ----------------------------------- */
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.css';
/* ----------------------------------- */
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
 iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
 iconUrl: require("leaflet/dist/images/marker-icon.png"),
 shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
Vue.use(PiniaVuePlugin)

Vue.config.productionTip = false;
const pinia = createPinia();

Vue.component('table-component', TableComponent);
Vue.component('text-field-component', TextField);
Vue.component('select-component', SelectComponent);
Vue.component('switch-component', SwitchComponent);
Vue.component('date-picker-component', DatePickerComponent);
Vue.component('text-area-component', TextAreaComponent);
Vue.component('snackbar-component', SnackBarComponent);
Vue.component('file-input-component', FileInputComponent);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('load-data-component', LoadData);
Vue.component('view-pdf-component', ViewPdfComponent);
Vue.component('time-picker-component', TimepickerComponent);
Vue.component('dialog-yes-no-component', DialogYesNoComponent);
Vue.component('check-box-component', CheckBoxComponent);
Vue.use(VueSweetalert2);
new Vue({
  router,
  pinia,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");