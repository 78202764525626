<template>
    <v-row justify="end" class="mr-1">
      <v-dialog
        v-model="dialog"
        persistent
      >
        <v-card>
          <LoadData ref="load"></LoadData>
          <DocumentosForm ref="documentoFormRef" :mostrarTabla="false" @actualiza-documentos="actualizaTablaDocumentos"></DocumentosForm>
          <snackbar-component :snackbar.sync="snackbar" :texto="textoSnackBar" :estado="estadoSnackBar" :style_="'margin-top: 180px;'"></snackbar-component>
          <v-toolbar flat class="dialog-toolbar">
            <v-toolbar-title class="text-h5">Compromiso</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="cancelar">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <!-- <v-card-title>
            <span>Compromiso</span>
          </v-card-title> -->
          <v-card-text style="padding-bottom: 0px;">
            <v-form ref="form" v-model="valid" lazy-validation :disabled="disabled">
              <v-container>
                <v-row>
                  <v-col cols="6">
                    <text-field-component label="Código *" ref="cfCodigo" :valor="codigo" type="numerico" required :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="6">
                    <text-field-component label="Nombre *" ref="cfnombre" :valor="nombre" required :disabled="disabled"></text-field-component>
                  </v-col>
                </v-row>
                <v-row class="mt-8">
                  <v-col cols="6" style="margin-top: 14px;">
                    <text-field-component label="Autoridad" ref="cfAutoridad" :valor="autoridad" semaforo :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="6">
                    <select-component label="Verificador" ref="cfverificador" url="/verificadores" :multiple="true" :valorMultiple="verificador" semaforo :disabled="disabled"></select-component>
                  </v-col>
                  <v-col cols="6">
                    <text-area-component label="Descripción *" ref="cfdescripcion" :valor="descripcion" required :counterValue="5000"></text-area-component>
                  </v-col>
                  <v-col cols="6">
                    <text-area-component label="Descripción del verificador" ref="cfdescverif" :valor="descripcion_verificador" semaforo :counterValue="5000"></text-area-component>
                  </v-col>
                  <v-col cols="6">
                    <text-field-component label="Origen *" ref="cforigen" :valor="origen" required :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="6">
                    <text-field-component label="Norma" ref="cfnorma" :valor="norma" semaforo :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="6" class="mt-4">
                    <select-component label="Componente" ref="cfcomponente" url="/componentes" :multiple="true" :valorMultiple="componente" semaforo :disabled="disabled"></select-component>
                  </v-col>
                  <v-col cols="6" class="mt-7">
                    <select-component label="Fase *" ref="cffase" url="/fases" :valor="fase" clearable required :disabled="disabled"></select-component>
                  </v-col>
                  <!-- <v-col cols="6" class="mt-6">
                    <select-component label="Frecuencia" ref="cffrecuencia" url="/frecuencias" :valor="frecuencia" clearable semaforo :disabled="disabled"></select-component>
                  </v-col> -->
                  <v-col cols="6" class="mt-6">
                    <select-component label="Frecuencia *" ref="cffrecuenciasugerida" url="/frecuencias" :valor="frecuencia_sugerida" clearable required :disabled="disabled"></select-component>
                  </v-col>
                  <v-col cols="6" class="mt-6">
                    <date-picker-component label="Primer Vencimiento" ref="dpVencimiento" :valor="vencimiento"></date-picker-component>
                  </v-col>
                  <v-col cols="6" class="mt-2">
                    <text-field-component label="Responsable" ref="tfResponsable" :valor="responsable" :disabled="disabled"></text-field-component>
                  </v-col>
                  <v-col cols="6" style="margin-top: -6px">
                    <select-component label="RCA" ref="cmbRca" :url="'rca/' + proyecto_id" clearable itemValue="id_expediente" itemText="nombre" :multiple="true" :valorMultiple="rca" :disabled="disabled"></select-component>
                  </v-col>
                  <v-col cols="12">
                    <text-area-component label="Observación" ref="cfObservacion" :valor="observacion" :counterValue="5000" :rows="3"></text-area-component>
                  </v-col>
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="4">
                        <select-component label="Estado" ref="cmbEstados" url="estados/categoria/C" :valor="estado" clearable :disabled="disabled"></select-component>
                      </v-col>
                      <v-col cols="4">
                        <v-btn
                          color="blue-grey"
                          class="white--text"
                          @click="cargarDocumento"
                          v-if="!disabled"
                        >
                          Cargar Antecedente/Verificador
                          <v-icon dark class="ml-1">
                              mdi-cloud-upload
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-card-actions style="padding-top: 0px;">
            <v-spacer></v-spacer>
            <v-btn
              class="ma-2"
              large
              @click="cancelar"
            >
              Cerrar
            </v-btn>
            <v-btn
              v-if="!disabled"
              class="ma-2"
              dark
              color="cyan"
              large
              @click="guardarCompromiso"
            >
              <v-icon left>
                mdi-content-save
              </v-icon>
              Guardar
            </v-btn>
          </v-card-actions>
          <v-card class="ml-8 mb-2 mr-6">
            <v-card-title>
              <span>Antecedente/Verificador</span>
            </v-card-title>
            <v-card-text>
              <table-component ref="docsRef" :headers="headers_documentos" :url="'/documentos/compromiso/' + compromiso_id" view="compromiso-documento" :disabled="disabled" labelSearch="Búsqueda de documentos" :tableHeight=250 :showAdd="disabled" :exportExcel="false" :key="keyTablaDocs" :disbleItemsPerPage="true" :disablePagination="true" :rowsPerPage=-1></table-component>
            </v-card-text>
          </v-card>
          <v-card class="ml-8 mr-6">
            <v-card-title>
              <span>Historial compromiso</span>
            </v-card-title>
            <v-card-text>
              <table-component ref="informesRef" :headers="headers_historico" :url="'/historico/visita/' + compromiso_id" view="ficha-historico" :padreId="compromiso_id" :disabled="disabled" labelSearch="Búsqueda de historico" :tableHeight=300 :showAdd="disabled" :exportExcel="false" :key="keyHistorico" :disbleItemsPerPage="true" :disablePagination="true" :rowsPerPage=-1></table-component>
            </v-card-text>
          </v-card>
        </v-card>
      </v-dialog>
    </v-row>
  </template>
  <script>
    import axios from "axios";
    import LoadData from '@/components/LoadData.vue'
    import { EventBus } from "@/event/event"
    import DocumentosForm from "./DocumentosForm.vue";
    import { commonStore } from "@/store/CommonStore";
  
    export default {
      components: {
        LoadData,
        DocumentosForm
      },
      data: () => ({
        dialog: false,
        disabled: false,
        valid: true,
        item: [],
        nombre: '',
        descripcion: '',
        origen: '',
        norma: '',
        descripcion_verificador: '',
        componente: [],
        fase: null,
        frecuencia: null,
        frecuencia_sugerida: null,
        verificador: [],
        vencimiento: '',
        view: '',
        reverse: false,
        estado: '',
        compromiso_id: 0,
        headers_historico: [
          {text: 'Campo', value: 'campo', width: '100px', filtro:false, sortable: true},
          {text: 'Visita', value: 'visita', width: '200px', filtro:false, sortable: true},
          {text: 'Tipo', value: 'tipo', width: '170px', filtro:false, sortable: true},
          {text: 'Valor Anterior', value: 'valorAnterior', width: '250px', filtro:false, sortable: false},
          {text: 'Valor nuevo', value: 'valorANuevo', width: '250px', filtro:false, sortable: false},
          {text: 'Usuario', value: 'usuario', width: '200px', filtro:false, sortable: false},
          {text: 'Fecha/Hora', value: 'fecha', width: '200px', filtro:false, sortable: false},
          {text: 'Acciones', value: 'actions', filtro:false, align: 'center', width: '120px'}
        ],
        keyHistorico: 0,
        headers_documentos: [
          { text: 'Nombre documento', value: 'nombre', filtro:false },
          { text: 'Fecha creación', value: 'created_at', filtro:false },
          { text: 'Acciones', value: 'actions', filtro:false, align: 'center' }
        ],
        keyTablaDocs: 0,
        codigo: '',
        autoridad: '',
        rca: [],
        proyecto_id: 0,
        responsable: '',
        observacion: '',
        commonStore: commonStore(),
        snackbar: false,
        textoSnackBar: '',
        estadoSnackBar: 0
      }),
      methods: {
        open(item, disabled, view) {
          ++this.keyHistorico
          ++this.keyTablaDocs
          this.item = item
          if (item.proyecto_id) {
            this.proyecto_id = item.proyecto_id
          } else {
            this.proyecto_id = item.id
          }
          if (view == 'ficha-compromiso') {
            this.view = view
          }
          this.seleccionRCA(this.proyecto_id, item.instrumento)
          if (item.compromiso_id) {
            this.compromiso_id = item.compromiso_id
            if (this.$refs.form) {
              if (!item.rca) {
                this.$refs.cfnombre.setValue(item.nombre)
                this.$refs.cfdescripcion.setValue(item.descripcion)
                this.$refs.cforigen.setValue(item.origen)
                this.$refs.cfnorma.setValue(item.norma)
                this.$refs.cfdescverif.setValue(item.descripcion_verificador)
                this.$refs.cfcomponente.setValue(item.componente)
                this.$refs.cffase.setValue(item.fase)
                /* this.$refs.cffrecuencia.setValue(item.frecuencia) */
                this.$refs.cffrecuenciasugerida.setValue(item.frecuencia_sugerida)
                this.$refs.cfverificador.setValue(item.verificador)
                this.$refs.dpVencimiento.setValue(item.vencimiento)
                this.$refs.cmbEstados.setValue(item.estado)
                this.$refs.cfCodigo.setValue(item.codigo)
                this.$refs.cfAutoridad.setValue(item.autoridad)
                this.$refs.cmbRca.setValue(item.instrumento)
                this.$refs.tfResponsable.setValue(item.responsable)
                this.$refs.cfObservacion.setValue(item.observacion)
              }
            } else {
              if (!item.rca) {
                this.nombre = item.nombre
                this.descripcion = item.descripcion
                this.origen = item.origen
                this.norma = item.norma
                this.descripcion_verificador = item.descripcion_verificador
                this.componente = item.componente
                this.fase = item.fase
                this.frecuencia = item.frecuencia
                this.frecuencia_sugerida = item.frecuencia_sugerida
                this.verificador = item.verificador
                this.vencimiento = item.vencimiento
                this.estado = item.estado
                this.codigo = item.codigo
                this.autoridad = item.autoridad
                this.rca = item.instrumento
                this.responsable = item.responsable
                this.observacion = item.observacion
              }
            }
          }
          this.disabled = disabled
          this.dialog = true
        },
        cancelar() {
          if (!this.disabled) {
            if (this.editado()) {
              this.$swal.fire({
                title: "¿Desea guardar los cambios efectuados?",
                icon: 'warning',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: "Guardar",
                denyButtonText: 'No guardar',
                cancelButtonText: "Cancelar",
                confirmButtonColor: "#4caf50",
                allowOutsideClick: false,
                showCloseButton: true
              }).then((result) => {
                if (result.isConfirmed) {
                  this.guardarCompromiso()
                  if (this.$refs.form.validate()) {
                    this.dialog = false
                  }
                } else if (result.isDenied) {
                  this.$refs.form.reset();
                  this.$refs.form.resetValidation();
                  this.keyHistorico++
                  this.dialog = false
                  this.refreshEditado()
                }
              })
            } else {
              this.$refs.form.reset();
              this.$refs.form.resetValidation();
              this.keyHistorico++
              this.dialog = false
            }
          } else {
            this.$refs.form.reset();
            this.$refs.form.resetValidation();
            this.keyHistorico++
            this.dialog = false
          }
          EventBus.$emit('refresh-compromisos-uf')
        },
        async guardarCompromiso() {
          if (this.$refs.form.validate()) {
            this.$refs.load.open();
            let componentes = this.$refs.cfcomponente.getValue();
            let componentes_id = []
            componentes.forEach(element => {
              componentes_id.push(element.id);
            });
            let instrumentos = this.$refs.cmbRca.getValue()
            let instrumentos_id = []
            instrumentos.forEach(instrumento => {
              instrumentos_id.push(instrumento.id)
            })
            let verificadores = this.$refs.cfverificador.getValue()
            let verificador_ids = []
            verificadores.forEach(verificador => {
              verificador_ids.push(verificador.id)
            })
            let estado = this.$refs.cmbEstados.getValue() ? this.$refs.cmbEstados.getValue().id : null
            const data = {
              proyecto_id: this.item.id,
              nombre: this.$refs.cfnombre.getValue(),
              descripcion: this.$refs.cfdescripcion.getValue(),
              origen: this.$refs.cforigen.getValue(),
              norma: this.$refs.cfnorma.getValue(),
              descripcion_verificador: this.$refs.cfdescverif.getValue(),
              vencimiento: this.$refs.dpVencimiento.getValue(),
              componentes: componentes_id,
              fase_id: this.$refs.cffase.getValue() ? this.$refs.cffase.getValue().id : null,
              verificador: verificador_ids,
              frecuencia_id: /* this.$refs.cffrecuencia.getValue() ? this.$refs.cffrecuencia.getValue().id :  */null,
              frecuencia_sugerida_id: this.$refs.cffrecuenciasugerida.getValue() ? this.$refs.cffrecuenciasugerida.getValue().id : null,
              estado_id: this.$refs.cmbEstados.getValue() ? this.$refs.cmbEstados.getValue().id : null,
              codigo: this.$refs.cfCodigo.getValue(),
              instrumento: instrumentos_id,
              autoridad: this.$refs.cfAutoridad.getValue(),
              responsable: this.$refs.tfResponsable.getValue(),
              observacion: this.$refs.cfObservacion.getValue()
            }
            if (this.item.compromiso_id) {
              await axios.put('compromisos/' + this.item.compromiso_id, data)
              .then(response => {
                if (estado && estado == this.commonStore.estadoCompromisoCumple) {
                  this.actualizarConfirmacion()
                }
                this.refreshEditado()
                this.afterService(response)
              })
              .catch( error => {
                EventBus.$emit('refresh-table', error.response)
                EventBus.$off('refresh-table')
                console.error(error)
                this.$refs.load.close();
              })
            } else {
              await axios.post('compromisos', data)
              .then(response => {
                this.item.compromiso_id = response.data.body.items.id
                this.refreshEditado()
                this.afterService(response)
              })
              .catch( error => {
                EventBus.$emit('refresh-table', error.response)
                EventBus.$off('refresh-table')
                console.error(error)
                this.$refs.load.close();
              })
            }
          }
        },
        afterService(response) {
          EventBus.$emit('refresh-table', response)
          EventBus.$off('refresh-table')
          //EventBus.$emit('refresh-compromisos-uf', response)
          this.$refs.load.close();
        },
        cargarDocumento() {
          this.$refs.documentoFormRef.open(this.item, false);
        },
        actualizaTablaDocumentos() {
          this.keyTablaDocs++
        },
        refreshEditado() {
          this.$refs.cfnombre.setEditado(false)
          this.$refs.cfdescripcion.setEditado(false)
          this.$refs.cfObservacion.setEditado(false)
          this.$refs.cforigen.setEditado(false)
          this.$refs.cfnorma.setEditado(false)
          this.$refs.cfdescverif.setEditado(false)
          this.$refs.dpVencimiento.setEditado(false)
          this.$refs.cfcomponente.setEditado(false)
          this.$refs.cffase.setEditado(false)
          this.$refs.cfverificador.setEditado(false)
          /* this.$refs.cffrecuencia.setEditado(false) */
          this.$refs.cffrecuenciasugerida.setEditado(false)
          this.$refs.cmbEstados.setEditado(false)
          this.$refs.cfCodigo.setEditado(false)
          this.$refs.cmbRca.setEditado(false)
          this.$refs.cfAutoridad.setEditado(false)
        },
        editado() {
          if (this.$refs.cfnombre.getEditado() ||
          this.$refs.cfdescripcion.getEditado() ||
          this.$refs.cfObservacion.getEditado() ||
          this.$refs.cforigen.getEditado() ||
          this.$refs.cfnorma.getEditado() ||
          this.$refs.cfdescverif.getEditado() ||
          this.$refs.dpVencimiento.getEditado() ||
          this.$refs.cfcomponente.getEditado() ||
          this.$refs.cffase.getEditado() ||
          this.$refs.cfverificador.getEditado() ||
          /* this.$refs.cffrecuencia.getEditado() || */
          this.$refs.cffrecuenciasugerida.getEditado() ||
          this.$refs.cmbEstados.getEditado() ||
          this.$refs.cfCodigo.getEditado() ||
          this.$refs.cmbRca.getEditado() ||
          this.$refs.cfAutoridad.getEditado()) {
            return true
          } else {
            return false
          }
        },
        async seleccionRCA(proyecto_id, compromisoRca) {
          if (compromisoRca) {
            if (compromisoRca.length == 0) {
              await axios.get('rca/' + proyecto_id, {})
              .then((response) => {
                if (response.data.body.items.length == 1) {
                  this.$refs.cmbRca.setValue(response.data.body.items)
                }
              }).catch((error) => {
                console.error(error)
              })
            }
          } else {
            await axios.get('rca/' + proyecto_id, {})
              .then((response) => {
                if (response.data.body.items.length == 1) {
                  this.$refs.cmbRca.setValue(response.data.body.items)
                }
              }).catch((error) => {
                console.error(error)
              })
          }
        },
        async actualizarConfirmacion() {
          const data = {
              accion: 'e'
          }
          await axios.put('/compromiso/confirmacion/' + this.item.compromiso_id, data).then(response => {
              if (response.data.message != '') {
                this.snackbar = true;
                this.textoSnackBar = response.data.message
                this.estadoSnackBar = 204 //response.data.code
              }
          }).catch(error => {
            console.error(error)
            this.snackbar = true;
            this.textoSnackBar = error.response.data.message
            this.estadoSnackBar = error.response.data.code
          })
        }
      },
      mounted() {
        EventBus.$on('refresh-documentos', (response) => {
          this.keyTablaDocs++
        });
      }
    }
  </script>

<style scoped>
  .dialog-toolbar {
    position: sticky;
    top: 0;
    margin-bottom: 25px;
    background-color: white; /* Cambia esto al color que desees */
    z-index: 2;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  .timeline-container {
    max-height: 400px; /* Ajusta la altura máxima según tus necesidades */
    overflow-y: auto;
  }
</style>