import { defineStore } from 'pinia';
import CryptoJS from 'crypto-js';

export const perfilStore = defineStore('perfil', {
  state: () => {
    return {
      administrador: 1,
      jefatura: 2,
      analista: 3,
      titular: 4,
    }
  },
  actions: {
    encryptData(data) {
      //const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), process.env.VUE_APP_KEY);
      const encrypted = btoa(this.arrayHandler(data.toString().split(""), process.env.VUE_APP_KEY.toString().split("")))
      return encrypted.toString();
    },
    decryptData(encryptedData) {
      //const decrypted = CryptoJS.AES.decrypt(encryptedData, process.env.VUE_APP_KEY);
      //return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
      const decrypted = atob(encryptedData);
      let resto = decrypted.toString().length - process.env.VUE_APP_KEY.toString().length;
      let texto = '';
      for (let i = 0; i < decrypted.length; i++) {
          texto += decrypted[i];
          ++i;
          if (texto.length == resto) {
              break;
          }
      }
      return texto.toString()
    },
    arrayHandler(array1, array2) {
      let retorno = ''
      for (var i = 0; i < array2.length; i++) {
        retorno += array1[i] ? array1[i] : ''
        retorno += array2[i] ? array2[i] : ''
      }
      return retorno
    }
  }
})